// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
  500: white,
  contrast: (
    500: black,
  ),
);

$fuse-black: (
  500: black,
  contrast: (
    500: white,
  ),
);

$fuse-navy: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #3c4252,
  600: #353a48,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$sidebar: (
	500: #050C32,
	contrast: (
		500: white,
	),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  fuse-white: $fuse-white,
  fuse-black: $fuse-black,
  fuse-navy: $fuse-navy,
	sidebar: $sidebar,
);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-legacy-typography-config(
  $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
  $title: mat.define-typography-level(20px, 32px, 600),
  $body-2: mat.define-typography-level(14px, 24px, 600),
  $button: mat.define-typography-level(14px, 14px, 600),
  $input: mat.define-typography-level(16px, 1.125, 400) // line-height must be unitless !!!,,,,
);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------
@import 'project-page/project.theme';

@import 'authentication/login/login.theme';
@import 'authentication/register/register.theme';
@import 'authentication/register-2/register-2.theme';
@import 'authentication/forgot-password/forgot-password.theme';
@import 'authentication/forgot-password-2/forgot-password-2.theme';
@import 'authentication/mail-confirm/mail-confirm.theme';
@import 'errors/404/error-404.theme';
@import 'errors/500/error-500.theme';

// Define a mixin for easier access
@mixin components-theme($theme) {

  // Apps

  @include dashboards-project-theme($theme);

  // Pages
  @include login-theme($theme);
  @include register-theme($theme);
  @include register-2-theme($theme);
  @include forgot-password-theme($theme);
  @include forgot-password-2-theme($theme);
  @include mail-confirm-theme($theme);
  @include error-404-theme($theme);
  @include error-500-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  );
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);


// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette,
  );
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);


// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $blue-gray-dark-theme-primary-palette,
    accent: $blue-gray-dark-theme-accent-palette,
    warn: $blue-gray-dark-theme-warn-palette,
  );
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);


// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette,
  );

}

body {
  margin: 0;   /* Remove body margins */
  font-family: "Muli", "Helvetica Neue", "Arial", sans-serif;
}
