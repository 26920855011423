@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;
// Import app.theme.scss
@import 'components/app.theme';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/css/regular.css';
@import '@fortawesome/fontawesome-free/css/brands.css';
@import "@progress/kendo-font-icons/dist/index.css";
@import "@progress/kendo-svg-icons/dist/index.css";


$aegis-blue: #001489;
$aegis-yellow: #ffb500;
$new-background-color: #C8CAD6;
$good-text-color-t1: #0059FF;
$bad-text-color-t1: #DF5353;
$moderate-text-color-t1: #4fc931;

@include mat.core();

$theme: matx.define-theme((
				color: (
								theme-type: light,
								primary: matx.$m3-azure-palette,
								tertiary: matx.$m3-blue-palette,
				)
));

:root {
	@include mat.all-component-themes($theme);
}

html,body,div{-webkit-print-color-adjust: exact;}

html,body {
	background: #c8cad6;
}

button.gm-ui-hover-effect {
	height: 24px !important;
	width: 24px !important;
	> span {
		margin: 0 !important;
	}
}

.gm-bundled-control {
	height: 49px;
	width: 24px;
	margin: 5px !important;
	> div:first-child {
		height: 49px;
		width: 24px;
		> div:first-child {
			height: 49px !important;
			width: 24px !important;
			> button {
				height: 24px !important;
				width: 24px !important;
				border-radius: 4px;

				> img {
					height: 14px !important;
					width: 14px !important;
				}
			}
			> div {
				width: 18px !important;
				margin: 0px 3px !important;
			}
		}
	}
}

.k-button-icon .k-image {
	width: 20px;
	height: 20px;
}

ion-card-header {
	background-color: #011689;
	color: white;
}

.tooltip-hide {
	display: none;
}

body.theme-default {
	background: #c8cad6;
}

.k-label-optional {
  display: none;
}
.k-dialog-titlebar {
  background: $aegis-blue !important;
}

.k-breakdown-custom{
  height: 122px !important;
}

.k-tabstrip-items-wrapper .k-item.k-active {
  background-color: $aegis-blue !important;
  color: white !important;
}

.k-pager-numbers .k-link {
  color: #111111 !important;
}

.k-tabstrip-items-wrapper .k-item {
	color: #011169 !important;
	background: rgba(1, 17, 105, .1);
	border: .5px solid #011169;
}


.social-image {
  width: 30px; height: 30px; margin-top: 5px;
  border-radius: 15%;
  color: white;
  font-size: 25px;
}

#atlwdg-trigger {
  z-index: 9001;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s 3 ease-in-out;
	opacity: 0;
	display: none;
}

.controls-menu {
  --width: 450px;
}

.crossout {
  text-decoration: line-through;
  color: gray !important;
  font-style: italic;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rhino-spin {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  width: 40px;
  height: 38px;
}

@keyframes cowmove{
	0% {
		transform: translateX(100px) rotateY(0deg);
	}
	49% {
		transform: translateX(1000px) rotateY(0deg);
	}
	50% {
		transform: translateX(1000px) rotateY(360deg);
	}
	100% {
		transform: translateX(100px) rotateY(360deg);
	}
}

.across-the-screen {
	position: absolute;
	z-index: 100000;
	cursor: pointer;
}

.rhino-done {
  animation: pulse 2s 3 ease-in-out;
}
.nothanks {
  background: white !important;
}

//these datatable icons replace the swimlane/ngx ones that broke during angular upgrade
.datatable-icon-prev:before {
  content: '\72';
}

.datatable-icon-left:before {
  content: '\6f';
}

.datatable-icon-skip:before {
  content: '\71';
}

.datatable-icon-right:before {
  content: '\70';
}

.banner-section {
  padding: 2px;
}

.left-section {
  padding-left: 0;
}

.right-section {
  padding-right: 0;
}

.no-data-chart-warning {
	text-align: center;
	display: flex;
	justify-content: center;
	width: 80%;
	height: 100%;
	margin: auto;
	align-items: center;
	cursor: default;
}

.k-checkbox:checked {
	background-color: #039BE5;
	border-color: #039BE5;
}

.k-checkbox:indeterminate {
	color: #039BE5;
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23039BE5' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M4,8 h8'/></svg>");
}

.k-progressbar .k-selected {
	color: white;
	background-color: #039BE5;
	border-color: #039BE5;
}

.edit-notes-dialog .mat-dialog-container {
	padding: 12px 18px;
}
.unsaved-changes-dialog .mat-dialog-container {
	padding: 12px 18px;
}
textarea[id] {
	height: 100%;
}
textarea.k-input-inner {
	height: 100%;
}
.k-switch-md .k-switch-label-on {
	left: 8px;
}
.k-switch-off .k-switch-track, {
	border-color: #001489;
}
.k-switch-on .k-switch-track {
	border-color: #001489;
	background-color: #ffb500;
	color: black;
}
.k-switch-off .k-switch-thumb, .k-switch-on .k-switch-thumb {
	background-color: #001489;
}

.k-filter .k-filter-toolbar {
	display: block;
}

.fixed-position-underlay {
	position: fixed;
	overflow-y: scroll;
	width: 100% !important;
}

.k-filter .k-filter-toolbar .k-toolbar {
	padding: 0;
}

.k-widget.k-filter {
	min-width: 100%;
	padding-left: 8px;
}

.k-filter-toolbar-item:last-child {
	margin-left: auto;
}
.eula-k .k-window .k-dialog-titlebar .k-window-titlebar-actions {
	display: none;
}
.view-icon-btn {
	--background: var(--ion-color-success, green);
	--color: var(--ion-color-primary-contrast, #fff);
	background: green;
	border-radius: 5px;
}

.hide-title-actions .k-window-titlebar-actions.k-dialog-titlebar-actions {
	display: none;
}

.solidgauge-label {
	margin-top: -50px !important;
	padding-bottom: 0;
	margin-bottom: 0;
}

.blur-text-div {
	margin: 0 20vw;
	align-content: center;
	font-size: 32px;
}

.blur-div {
	position: absolute;
	text-align: center;
	z-index: 99999;
	height: calc(100vh - 56px);
	width: 100%;
	background: #C8CAD6;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.warning-div {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 16px;
}

.new-background {
	background-color: $new-background-color;
}

.proj-top-row-predictability-trend > .k-chart{
	margin: 0 !important;
	height: calc(100% - 4px) !important;
	padding: 2px;
}

.proj-top-row-donut-chart > .k-chart{
	margin: 0 !important;
	height: calc(100% - 4px) !important;
	padding: 2px;
}

.proj-top-row-score-trend > .k-chart{
	margin: 0 !important;
	height: calc(100% - 4px) !important;
	padding: 2px;
}

.project-list-grid {
	height: 100%;
	width: 100%;
}

.full-width {
	width: 100%;
}
.full-height {
	height: 100%;
}

.gridlayout-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#criticalPathComparisonGanttChart > div > kendo-splitter > kendo-splitter-pane > kendo-gantt-timeline > div > .k-grid-content {
	overflow-y: hidden;
}

.center-text {
	text-align: center !important;
}

#criticalPathComparisonGanttChart {
	kendo-gantt-summary-task {
		margin-top: 5px;
	}
}

#criticalPathComparisonGanttChart, #schedule-lookahead-gantt, #drivingPathGantt {
	.k-gantt-timeline {
		> div.k-grid-header {
			padding-right: 0;
			> div > table > tbody {
				> tr:first-child {
					background-color: #fafafa;
					height: 28px;
				}
				> tr:last-child {
					//display: none;
					height: 28px;
					font-size: 12px;
				}
				> tr > td {
					padding: 0 !important;
				}
			}
		}
		> div.k-grid-content {
			overflow-x: hidden !important;
			overflow-y: auto !important;
			> .k-gantt-dependencies-svg {
				display: none;
			}
		}
	}
	.k-treelist {
		> div {
			> div > div > table {
				//width: 470px;
				> thead > tr {
					//height: 36px !important;
					height: 56px;
				}
			}
			> kendo-treelist-list {
				cursor: pointer;
				> .k-grid-content {
					margin-right: 0 !important;
				}
				> div {
					overflow-x: hidden !important;
					overflow-y: auto !important;
					scrollbar-width: none;
					> .k-grid-table-wrap > table > tbody > .k-selected {
						background-color: transparent;
						> td {
							background-color: rgba(18, 116, 172, 0.25);
						}
					}
				}
			}
		}
	}
	.k-grid-header {
		overflow-x: hidden;
		overflow-y: hidden;
	}
}

span.k-task-actions {
	visibility: hidden !important;
}

table.k-table.k-table-md.k-gantt-tasks {
	> tbody > tr {
		height: 36px;
		> td {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

kendo-gantt-task {
	height: 20px;
	padding-top: 0;
	padding-bottom: 0;
}


kendo-splitter-pane.k-gantt-treelist.k-gantt-treelist-scrollable.k-pane {
	user-select: text;
	pointer-events: all;
}

.k-summary-wrap > div {
	pointer-events: all;
}

.login-icon-setup {
	cursor: pointer;
	width: 30px;
	height: 30px;
}

.blue-score {
	color: rgba($good-text-color-t1, 0.9);
	cursor: pointer;
}
.green-score {
	color: rgba($moderate-text-color-t1, 0.9);
}
.red-score {
	color: rgba($bad-text-color-t1, 0.9);
}

.blue-score-selected {
	background-color: rgba($good-text-color-t1, 0.9) !important;
	color: white;
}
.green-score-selected {
	background-color: rgba($moderate-text-color-t1, 0.9) !important;
	color: white;
}
.red-score-selected {
	background-color: rgba($bad-text-color-t1, 0.9) !important;
	color: white;
}

.not-selected-score {
	color: #737373;
}

.clickable {
	cursor: pointer;
}

.card-header {
	font-weight: bold;
	font-size: 16px;
	margin: auto 0;
}

.blur-page {
	filter: blur(10px);
	height: calc(100vh - 56px);
	overflow: hidden;
	padding: 0 !important;
}

.card-header-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	background-color: $aegis-blue;
	color: white;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding-left: 10px;
	> global-tooltip-open {
		margin: auto 0;
		padding-left: 5px;
	}
	> tooltip-open {
		margin: auto 0;
		padding-left: 5px;
	}
}

.card-header-container-score-impact {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: $aegis-blue;
	color: white;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	white-space: nowrap;
}

.shadow {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.risk-card-header-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	background-color: #930304;
	color: white;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding-left: 10px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
	> global-tooltip-open {
		margin: auto 0;
		padding-left: 5px;
	}
}

.card-content {
	background-color: white;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 2px solid transparent;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.likelihood-chart-container {
	height: 100%;
	background-color: white;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

kendo-gantt-summary-task.k-task-wrap, kendo-gantt-summary-task.k-task-wrap.k-summary-wrap.ng-star-inserted {
	padding-top: 0;
	padding-bottom: 0;
	> .k-task-summary {
		height: 15px;
	}
}


.k-gantt .k-gantt-dependencies-svg polyline {
	stroke-width: 1.5px;
}

.card {
	border-radius: 4px;
	background-color: white;
	height: 100%;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.row-start-aligned-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: auto 0;
	height: 100%;
	> global-tooltip-open {
		margin: auto 0;
		padding-left: 5px;
	}
	> tooltip-open {
		margin: auto 0;
		padding-left: 5px;
	}
}

.red {
	background-color: rgb(223, 83, 83);
	cursor: pointer;
	> .k-task-complete {
		background-color: rgb(255 136 136) !important;
	}
	> .k-task-summary-progress {
		height: 15px;
		> .k-task-summary-complete {
			height: 15px;
			background-color: rgb(255 136 136) !important;
		}
	}
}

.red2 {
	background-color: rgb(255, 129, 129);
	cursor: pointer;
	> .k-task-complete {
		background-color: rgb(255, 187, 187) !important;
	}
	> .k-task-summary-progress {
		height: 15px;
		> .k-task-summary-complete {
			height: 15px;
			background-color: rgb(255, 187, 187) !important;
		}
	}
}

.red3 {
	background-color: rgb(157, 16, 16);
	cursor: pointer;
	> .k-task-complete {
		background-color: rgb(192 62 62) !important;
	}
	> .k-task-summary-progress {
		height: 15px;
		> .k-task-summary-complete {
			height: 15px;
			background-color: rgb(192 62 62) !important;
		}
	}
}

.green {
	background-color: rgb(79, 201, 49);
	cursor: pointer;
	> .k-task-complete {
		background-color: rgb(133, 215, 115) !important;
	}
	> .k-task-summary-progress {
		height: 15px;
		> .k-task-summary-complete {
			height: 15px;
			background-color: rgb(133, 215, 115) !important;
		}
	}
}

.blue {
	background-color: rgb(0, 89, 255);
	cursor: pointer;
	> .k-task-complete {
		background-color: rgb(78, 140, 255) !important;
	}
	> .k-task-summary-progress {
		height: 15px;
		> .k-task-summary-complete {
			height: 15px;
			background-color: rgb(78, 140, 255) !important;
		}
	}
}

.gray {
	background-color: rgb(128, 128, 128);
	cursor: pointer;
	> .k-task-complete {
		background-color: rgb(177, 177, 177) !important;
	}
	> .k-task-summary-progress {
		height: 15px;
		> .k-task-summary-complete {
			height: 15px;
			background-color: rgb(177, 177, 177) !important;
		}
	}
}

.red-portfolio {
	//background-color: rgb(223, 83, 83);
	cursor: pointer;
	margin-top: 8px;
}

.green-portfolio {
	//background-color: rgb(79, 201, 49);
	cursor: pointer;
	margin-top: 8px;
}

tr.selectedDelayRow {
	background-color: transparent !important;
	> td {
		background-color: rgba(18, 116, 172, 0.25);
	}
}

kendo-gantt-task > .k-selected {
	color: white !important;
	background-color: #84b7d4 !important;
	cursor: pointer;
	> .k-task-complete {
		background-color: #1274AC !important;
	}
}

kendo-gantt-summary-task > .k-selected {
	background-color: #84b7d4 !important;
}

.k-task-milestone.k-selected {
	background-color: #84b7d4 !important;
	cursor: pointer;
}

.legend-btn {
	position: absolute;
	top: 0;
	right: 0;
	> kendo-icon-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 2px;
	}
}

.projects-grid > div > kendo-grid-list > div > .k-grid-table-wrap > table > tbody {
	> .k-selected > td {
		background-color: black !important;
		color: white !important;
		font-weight: bold;
	}

	> .k-detail-row > td {
		background-color: #f5f5f5;
	}
}

.toolbar-item-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin: auto;
	height: 100%;
	width: 100%;
}

.overview-notes-expansion-panel {
	border-radius: 4px;
	background-color: white;
	border: 2px solid #ededed;
	.k-expander-header {
		justify-content: center;
		background-color: #ededed;
		height: 32px;
		.k-spacer {
			flex: none;
		}
		.k-expander-sub-title {
			color: black;
		}
		.k-expander-indicator {
			margin-left: 5px;
		}
	}
	svg {
		fill: black;
	}
}

.overview-textarea {
	> textarea {
		background-color: #f8f7f7 !important;
	}
}

.rounded-bottom-corners {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

a.anchor-wrapping-back-arrow, a.anchor-wrapping-back-arrow:hover {
	text-decoration: none !important;
}

.portfolio-expansion-panel {
	> .k-expander {
		border: 2px solid $aegis-blue;
		> .k-expander-content-wrapper {
			order: 1;
			> .k-expander-content {
				padding: 0;
			}
		}
	}
	> .k-expander.loading {
		border: 2px solid #818181;
	}
	.k-expander-header {
		background-color: $aegis-blue !important;
		color: white !important;
		font-weight: bold;
		padding: 0 8px;
		height: 38.4px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		order: 2;
		position: relative;
		> .k-expander-indicator {
			display: none;
		}
	}
	.k-expander-title, .k-expander-sub-title {
		color: white !important;
		text-transform: capitalize !important;
		font-weight: bold;
	}
}

.admin-expansion-panel {
	background: transparent;
	border: none;
	box-shadow: none !important;
	> .k-expander-header {
		padding: 0;
		outline-color: transparent;
		> .k-expander-title {
			color: #21235c;
			text-transform: none;
		}
	}
	> .k-expander-content-wrapper {
		background-color: #ededed;
		border-radius: 4px;
		margin-top: 10px;
		> .k-expander-content {
			padding: 0;
		}
	}
}

.rw-menu-selection:hover {
	background-color: #21235c;
	color: white !important;
	border-radius: 4px;
	> kendo-expansionpanel {
		color: white;
		> .k-expander-header {
			color: white;
			> .k-expander-title {
				color: white;
			}
		}
		> .k-expander-content-wrapper > .k-expander-content > .content > .admin-btn{
				color: black;
		}
	}
}

#schedule-analysis-excel-export-spin, #schedule-list-spin {
	transform: scale(.1);
	position: absolute;
	left: -85px;
	top: -89px;
	.k-circular-progressbar-scale {
		display: none;
	}
}
#pf-table-spin {
	transform: scale(.1);
	position: absolute;
	right: 50px;
	top: -82px;
	.k-circular-progressbar-scale {
		display: none;
	}
}
#rr-mc-spin {
	transform: scale(.1);
	position: absolute;
	right: 80px;
	top: -85px;
	.k-circular-progressbar-scale {
		display: none;
	}
}
#project-settings-scores-tab-spin {
	transform: scale(.1);
	position: absolute;
	left: -93px;
	top: -90px;
	.k-circular-progressbar-scale {
		display: none;
	}
}
.indeterminate-progressbar {
	transform: scale(.1);
	position: absolute;
	left: -77px;
	top: -79px;
	.k-circular-progressbar-scale {
		display: none;
	}
}

.showing-loading-spinner {
	margin-left: 22px;
}

.opened-admin-panel {
	//border: 2px solid #21235c;
	background-color: #21235c;
	> .k-expander .k-expander-header .k-expander-title, .k-expander .k-expander-header .k-expander-indicator {
		color: white;
	}
}

.border-container > kendo-label, .portfolio-filter-container > kendo-label {
	display: flex;
	flex-direction: column;
	> label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	> kendo-multiselect, kendo-multiselecttree {
		border-color: rgba(0, 0, 0, 0.24);
		background-color: rgb(133 133 133 / 6%);
		> kendo-taglist {
			flex-flow: nowrap;
			> div {
				border-color: rgba(0, 0, 0, 0.24);
				min-width: 117.98px;
			}
		}
	}
}

.score-slider > div > button {
	height: 24px;
	width: 24px;
	> kendo-icon-wrapper {
		height: 16px;
		display: flex;
		justify-content: center;
		margin-top: 1px;
		> .k-i-caret-alt-right {
			margin-left: 2px;
		}
		> .k-i-caret-alt-left {
			margin-right: 2px;
		}
	}
}

.slider-reset-btn {
	padding: 0;
	height: 16px;
	width: 16px;
	> kendo-icon-wrapper {
		padding: 0;
		height: 16px;
		width: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 3px;
	}
}

.searchInput {
	width: 100%;
	background-color: rgb(133 133 133 / 6%);
	border-color: rgba(0, 0, 0, .24);
	height: 32px;
}

image-cropper > div {
	margin: auto 0;
}

.project-image-upload-dialog > .k-window > .k-window-content {
	padding: 10px;
}

.donut-chart {
	//margin-top: -15px;
	> div > svg > g > path {
		fill: transparent;
	}
}

.k-header > .k-cell-inner {
	justify-content: center;
}

.k-cell-inner {
	//display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin: auto 0;
	margin-block: 0;
	margin-inline: 0;
	> .k-link {
		padding: 8px 12px;
		width: auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: inherit;
		flex: 1;
		overflow: hidden;
	}
}

.k-grid .k-grid-header .k-table-th {
	vertical-align: middle;
}

.k-grid-header .k-header {
	vertical-align: middle;
	color: black;
	background-color: #dadada;
	font-weight: bold;
	text-align: center;
}

.k-grid-header th.k-grid-header-sticky, .k-grid-header td.k-grid-header-sticky, .k-grid-header .k-grid-header-sticky.k-sorted {
	color: black;
	background-color: #dadada;
	font-weight: bold;
}

kendo-grid .k-grid-content-sticky:hover, kendo-grid .k-grid-content-sticky.k-hover {
	background-color: #dadada;
}

kendo-grid tr:hover .k-grid-content-sticky, kendo-grid tr.k-hover .k-grid-content-sticky, kendo-grid .k-grid-row-sticky:hover td, kendo-grid .k-grid-row-sticky.k-hover td, kendo-grid .k-grid-row-sticky.k-alt:hover td, kendo-grid .k-grid-row-sticky.k-alt.k-hover td, kendo-grid .k-alt:hover .k-grid-content-sticky, kendo-grid .k-alt.k-hover .k-grid-content-sticky {
	background-color: #dadada;
}

.k-grid-header, .k-grid-footer {
	padding-right: 0px !important;
}

.saas-risk-top-active-risks-grid {
	border-radius: 0 0 4px 4px;
	user-select: text;
	> div > kendo-grid-list > div {
		overflow-y: hidden;
		border-radius: 0 0 4px 4px;
	}
	> div > div {
		padding-right: 0 !important;
	}
	> div > kendo-grid-list > div > .k-grid-table-wrap > table > tbody > tr > td {
		height: 32px;
		font-size: 12px;
	}
}

.center-for-real {
	text-align: center !important;
}

#notesComponentGrid {
	.notes-force {
		white-space: normal !important;
	}
	.k-grid-content {
		overflow-y: auto;
	}
	.k-grid-header {
		overflow-y: auto;
	}
}

.centered-data{
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.no-padding {
	padding: 0 !important;
}

.rr-table {
	border-radius: 0 0 4px 4px;
	> div {
		border-radius: 0 0 4px 4px;
	}
	> div > kendo-grid-list > div > .k-grid-table-wrap > table > tbody > tr > td {
		padding: 0px 5px !important;
		white-space: nowrap;
		//text-align: center;
		height: 32px;
	}
}

.score-trend-chart > kendo-chart {
	height: calc(100% - 4px) !important;
	margin-top: 2px !important;
}

.centerBoth {
	font-size: 50px !important;
	font-weight: 600 !important;
	width: 100%;
	text-align: center;
}

.filterBoxLabel {
	text-align:center;
	font-size: 0.75vw !important;
	width: 100%;
	white-space: break-spaces;
}

.mat-dialog-container {
	padding: 0px !important;
}

kendo-window, kendo-dialog {
	box-shadow: #0003 0 3px 1px -2px, #00000024 0 2px 2px, #0000001f 0 1px 5px !important;
	border-radius: 4px !important;
}

kendo-window-titlebar, kendo-dialog-titlebar, .k-window-titlebar, .k-dialog-titlebar {
	height: 21px;
	background-color: #C8CAD6 !important;
	border-radius: 4px 4px 0 0;
	color: black;
	text-align: left;
	font-size: 16px;
	border-width: 0px;
}

.k-chip {
	border-color: #0000003d;
	width: 144px;
}

.k-window-content {
	border: solid #C8CAD6;
	border-radius: 0 0 4px 4px;
	border-width: 0 8px 8px 8px;
}

.k-window.k-window-maximized {
	max-height: calc(100vh - 56px);
	top: 56px !important;
}

.update-report-window > .k-window-content {
	padding: 10px !important;
	overflow: hidden;
}

.k-popover-header {
	background-color: #050C32;
	color: white;
}

.scheduleDelayGrid {
	border-radius: 0 0 4px 4px;
	> div {
		border-radius: 0 0 4px 4px;
		> .k-grid-header {
			//overflow-y: auto;
		}
		> kendo-grid-list > .k-grid-content {
			//overflow-y: auto;
		}
	}
}

.schedule-update {
	border-radius: 0 0 4px 4px;
	> div {
		border-radius: 0 0 4px 4px;
	}
	> div > div > div > table > thead > tr > th > span > span > span {
		white-space: normal;
		text-align: center;
	}
	> div > kendo-grid-list > div > .k-grid-table-wrap > table > tbody > tr > td {
		padding: 0px 5px !important;
		white-space: normal;
		border-top: inherit;
		//text-align: center;
		height: 32px;
	}
}
.mat-mdc-icon-button {
	height: 32px !important;
	width: unset !important;
	padding: 4px 8px !important;
}
.mat-mdc-icon-button .mat-mdc-button-touch-target {
	height: 32px;
	width: unset;
}

.project-not-locked {
	display: none;
}

#schedules-grid tr.softDelete {
	background: #ebcaca !important;
}

#schedules-grid tr.softAdd {
	background: #bfd9bf;
}

#schedules-grid tr.newFinish {
	background: #bfd6d9;
}

#new-proj-form kendo-formfield, .fake-formfield, #profile-form kendo-formfield {
		margin-top: 0;
		height: 71.141px;
}

.readonly-textbox {
	background-color: #f2f2f2;
	cursor: not-allowed;
	color: #666666;
	> input, textarea {
		background-color: #f2f2f2;
		cursor: not-allowed;
		color: #666666;
	}
}

.disabled-cursor {
	cursor: not-allowed;
}

.loading-schedules-window {
	// should be above most but below nav bar popups (z-index: 100)
	z-index: 99 !important;
	kendo-window-titlebar {
		display: none;
	}
	.k-window-content {
		border: 0;
		border-radius: 4px;
		padding: 8px;
	}
}

fieldset {
	border-radius: 4px;
}

//google maps place autocomplete dropdown pane
.pac-container {
	z-index: 11111;
}

.hide-overflow {
	overflow: hidden;
}

.admin-settings-window .k-window-content {
	overflow: hidden;
}

#scorePlaygroundGrid {
	overflow-y: auto;
	border-radius: 4px;
	> div > div > div > table > thead > tr > th > span > span {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		> b {
			white-space: nowrap;
		}
	}
}

.header-checkbox:checked {
	background-color: white !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23039BE5' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e");
}
.greyCheck.header-checkbox:checked {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235c5c5c' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e") !important;
}

tooltip-open, global-tooltip-open {
	cursor: pointer;
}

#unsavedScheduleChangesWindow > .k-window-content {
	overflow: hidden;
}

.fixed-header {
	top: 56px;
	position: fixed;
	width: auto;
	z-index: 100;
}

.fixed-header-gantt {
	top: 56px;
	position: fixed;
	z-index: 100;
}

.fixed-buffer {
	padding-top: 36px !important;
}

.saas-risk-upload-btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 200px;
	button > kendo-icon-wrapper > kendo-icon {
		margin-top: -2px;
	}
}

/*.missing-update:after {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 0 10px 10px;
	border-color: transparent transparent transparent #ff8c00 ;
	left: 0;
	top: 0;
	position: absolute;
	//pointer-events: none;
}*/

.k-dialog {
	border-radius: 4px;
}

.seg {
	width: 80px;
	min-width: 75px;
	font-size: 14px;
	font-weight: bold;
}

.seg-schedules-popover {
	width: 100%;
	min-width: 75px;
	font-size: 14px;
	font-weight: bold;
}

ion-label {
	max-width: unset;
	line-height: 1.5;
}

.no-whitespace {
	margin: 0;
	padding: 0;
}

.tab-selector {
	height: auto;
	display: flex;
}

.tab-selector-schedules-popover {
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tab-selector-button-divider {
	border-left: thin solid white;
	height: 20px;
	position: relative;
	top: 7px;
	border-color: rgba(255, 255, 255, 0.5);
}

.tab-selector-button-divider-schedules-popover {
	border-top: thin solid white;
	border-left: none;
	height: 1px;
	position: relative;
	border-color: rgba(255, 255, 255, 0.5);
}

ion-segment-button {
	&:hover {
		--color: white;
		color: white;
		font-weight: bold;
		font-size: 12px;
	}
	--color-checked: #000000;
	--color: white;
	--color-focused: #000000;
	--color-hover: white;
}

kendo-grid {
	border-radius: 4px;
	> div {
		border-radius: 4px;
	}
}

.top-risks-grid {
	border-radius: 0 0 4px 4px;
	user-select: text;
	> div {
		border-radius: 0 0 4px 4px;
	}
}

#pfTable {
	tr, .tableRow {
		height: 20px !important;
		padding-left: 0px !important;
		margin: 0px !important;
	}
	th {
		background-color: #ededed !important;
		color: black !important;
	}
	.k-table-md .k-table-th, .k-table-md .k-table-td {
		padding: 0 0 0 3px !important;
	}
	.k-table-row.k-selected > td, .k-table-row.k-selected > .k-table-td {
		background-color: transparent;
	}
}

.a-link {
	color: #039be5 !important;
}
.a-link:hover {
	text-decoration: underline !important;
	cursor: pointer !important;
}

.k-table-th, .k-table-td {
	border-width: 0 0 0px 1px;
	border-style: solid;
	border-color: inherit;
	box-sizing: border-box;
	font-weight: 400;
	text-align: inherit;
	white-space: nowrap !important;
	text-overflow: ellipsis;
	display: table-cell;
	overflow: hidden;
	position: relative !important;
}

.footer-separator {
	text-align: center;
	padding: 8px 8px 2px 8px;
	> p {
		padding: 0;
		margin: 0;
	}
}

.footer-separator:before {
	content:"\a0";
	display:block;
	padding: 0;
	margin: 0;
	line-height:1px;
	border-top:1px solid rgba(0, 0, 0, 0.08);
}

.k-step-indicator-icon {
	margin-top: -2px;
}

.btn-group {
	padding: 2px;
	background-color: #050C32;
	border-radius: 20px;
	margin: 2px 0;
	width: 200px !important;
}

.item-btn {
	border-radius: 20px;
	background-color: #050C32;
	border-color: #050C32;
	color: white;
}

.item-btn:hover {
	background-color: inherit;
}

.k-button-solid-base:focus, .k-button-solid-base.k-focus {
	box-shadow: none;
}

.selected-item-btn {
	background-color: white !important;
	border-color: white !important;
	color: black !important;
	border-start-end-radius: 20px !important;
	border-end-end-radius: 20px !important;
	border-start-start-radius: 20px !important;
	border-end-start-radius: 20px !important;
	z-index: 3 !important;
}

.btngroup-label {
	margin: auto 5px auto 0;
	height: min-content;
	white-space: nowrap;
}

kendo-popup .k-disabled {
	color: #d0d0d0;
}

.projectSearchContainer {
	width: 300px;
	background-color: white;
	border-radius: 4px;
	display: flex;
	height: 24px;
	margin: auto 0 auto 5px;
	align-items: center;
}
.projectSearchContainer > .k-form {
	display: flex;
	width: 100%;
}
.projectSearchInput {
	width: 100%;
	border: none;
	background-color: rgba(133, 133, 133, 0.06);
}

/*.projectSearchInput {
	width: 100%;
	background-color: rgb(133 133 133 / 6%);
	border-color: rgba(0, 0, 0, .24);
	height: 24px;
!*	> span.k-clear-value {
		padding-bottom: 2px;
		> kendo-icon-warpper {
			height: 16px;
			margin-bottom: 4px;
		}
	}*!
}*/

.search-and-reset-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.project-list-filters-window-container > .k-window-content {
	padding: 0;
	border-bottom-width: 5px;
}

#ganttGroupByWindow > .k-window-content {
	padding: 0;
	border-bottom-width: 5px;
	overflow: hidden;
}

.compression-grid {
	max-height: 320px;
	border-radius: 0 0 4px 4px;
	> div {
		border-radius: 0 0 4px 4px;
	}
	.k-table-td {
		padding: 4px !important;
		max-height: 26px !important;
	}
}

.gantt .gantt-side {
	overflow-x: auto;
	overflow-y: hidden;
	//max-width: 635px;
}

.gantt .gantt-side .gantt-side-container {
	display: inline-block;
}

.center-shrink {
	font-size: 13px;
}

.subtext-popover-titles ion-col {
	font-size: 13px;
}
.subtext-popover-items ion-col {
	font-size: 12px;
}

.k-input-values {
	flex-flow: nowrap;
}

//.k-chart-tooltip-wrapper {
//	opacity: .6;
//}

.k-grid-header {
	overflow-x: hidden;
	overflow-y: scroll;
}

.mitStatusItem:nth-child(odd) {
	background-color: #f5f5f5;
}

.mitStatus-table-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100% - 17.5px);
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	font-size: 14px;
	white-space: nowrap;
	padding-top: 3px;
	.mitStatusItem {
		display: flex;
		flex-direction: row;
		justify-content: left;
		height: 100%;
		width: 100%;
		gap: 5px;
		.mitStatusLabel {
			width: calc(100% - 40px);
			overflow-x: hidden;
			text-overflow: ellipsis;
			padding-left: 6px;
			height: min-content;
			margin: auto 0;
		}
		.mitStatusVal {
			max-width: 27px;
			height: min-content;
			margin: auto 0;
		}
	}
}

.hideMonthLines {
	.k-gantt-timeline {
		.k-gantt-columns {
			.k-table-row {
				> td {
					border-left: transparent;
				}
			}
		}
	}
}
.gantt-year-view-no-months-project {
	.k-gantt-timeline {
		> div.k-grid-header {
			padding-right: 0;
			> div > table > tbody {
				> tr:first-child {
					background-color: #fafafa;
				}
				> tr:last-child {
					display: none;
				}
			}
		}
	}
	kendo-treelist {
		.k-table-thead > tr {
			height: 28px !important;
			> th {
				padding: 0 8px;
			}
		}
	}
}

.update-status-container {
	width: 100%;
	padding-top: 62px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 5px;
	> .update-status-title {
		text-align: center;
		font-size: 14px;
		white-space: nowrap;
	}
	> .checkbox-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		> input {
			border-color: rgba(0, 0, 0, 0.24);
		}
		> kendo-label > label {
			padding-left: 2px;
		}
	}
}
#presetDeleteDialog > .k-dialog > .k-dialog-content, #presetQuickSaveDialog > .k-dialog > .k-dialog-content {
	border-radius: 0;
	border-bottom: 0;
}

.invisible-multiselect-tag-selector {
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

kendo-popup.k-animation-container.k-animation-container-shown {
	width: auto !important;
}

.k-list-item.k-selected, .k-list-item.k-selected:hover {
	background-color: #1274ac40 !important;
	color: black;
}

.columns-popup-class {
	padding-bottom: 5px;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.columns-btns-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 5px;
	padding: 5px;
}

.updates-treeview {
	width: 130px;
	> ul > li {
		padding: 0 0 0 12px;
	}
}

.columns-treeview {
	width: 180px;
	> ul > li {
		padding: 0 0 0 12px;
	}
}

.groups-treeview {
	width: auto;
	> ul > li {
		padding: 0 0 0 12px;
	}
}

.selected-node {

	background-color: red;
	color: white;
}


.schedule-grid-update-treeview {
	width: 199px;
	> ul > li {
		padding: 0 0 0 12px;
	}
}

.box {
	border-radius: 4px;
	background-color: #df5353e6;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid rgba(0, 0, 0, .4);
	border-top-width: 0;
	border-left-width: 0;
	height: 100%;
	width: 100%;
	> div {
		width: 100%;
		text-align: center;
		white-space: break-spaces;
	}
}

.no-pad {
	padding: 0;
}

.test-label {
	font-size: 14px;
}

.green-bg {
	background-color: #4fc931e6;
}

#projectsGridProjectList {
	kendo-grid-list {
		cursor: pointer;
	}
}

.k-chart-crosshair-tooltip, .k-chart-shared-tooltip {
	color: black;
	background-color: rgb(235 235 235 / 70%);
	border-color: rgba(0, 0, 0, 0.08);
}

.preset-form-name {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 4px;
	.k-form-field-wrap {
		width: calc(100% - 92px);
	}
	kendo-label {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.report-name-field {
	.k-form-field-wrap {
		width: calc(100% - 44px);
	}
}

.progress-req {
	line-height: 1.1em;
	color: rgba(65, 65, 65, 0.75);
	font-style: italic;
	width: 40%;
	padding: 0 8px 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	font-size: 13px;
}

html {
	overflow-x: hidden;
}

.warning-bottom-section-container {
	padding: 5px;
	background: rgba(251, 97, 29, 0.9);

	border-radius: 4px;
}

.grid-btn-container {
	display: flex;
	height: 100%;
	width: 100%;
}

.grid-btn-score-impact {
	box-sizing: border-box;
	color: black;
	background-color: #ededed;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding: 5px;
	text-align: center;
	font-weight: bold;
	cursor: pointer;
}

.grid-btn {
	box-sizing: border-box;
	background-color: #ededed;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	width: 100%;
	padding: 5px;

	> div:first-child {
		font-size: 18px;
		text-align: center;
		font-weight: bold;
		white-space: nowrap;
		justify-content: flex-end;
		> img {
			width: 13px;
			height: 13px;
			margin-top: 4px;
		}
	}
}

.grid-btn-label {
	box-sizing: border-box;
	background-color: #ededed;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	width: 100%;
	padding: 5px;
	align-items: center;
	font-weight: bold;

	> div:first-child {
		font-size: 18px;
		text-align: center;
		white-space: nowrap;
		justify-content: flex-end;
		> img {
			width: 13px;
			height: 13px;
			margin-top: 4px;
		}
	}
}

.risk-gauge {
	kendo-arcgauge {
		vertical-align: middle;
		margin-top: 9px;
	}
}

.qc-gauge {
	kendo-arcgauge {
		vertical-align: middle;
		margin-top: 9px;
	}
}

.overviewHeader {
	background-color: #050C32;
	color: $aegis-yellow;
}

.details-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	color: white;
	background-color: #282828 !important;
	user-select: text;
	white-space: nowrap;
	font-size: 14px;
	> .details-item-row {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: 0px 5px;
		height: 24px;
		gap: 5px;
		> .details-label {
			width: 110px;
			min-width: 110px;
			text-align: start;
			display: block;
			margin: auto 0;
		}
		> .details-val {
			width: 190px;
			text-align: start;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;
			margin: auto 0;
		}
	}
	//> .details-item-row:nth-child(even) {
	//	background-color: #f5f5f5;
	//}
}

#schedDelayGrid {
	user-select: text;
	> div {
		> .k-grid-header {
			overflow-y: auto;
		}
		> kendo-grid-list > .k-grid-content {
			overflow-y: auto;
		}
	}
	.k-grid .k-table-row.k-selected > td, .k-grid .k-table-td.k-selected, .k-grid .k-table-row.k-selected > .k-table-td {
		background-color: rgb(0 0 0 / 84%);
		color: white;
	}
	.k-grid .right-align {
		text-align: right;
	}
	.k-grid .center-align {
		text-align: center;
	}
	.k-grid .no-padding {
		padding: 0 !important;
	}
	.k-table-td {
		max-height: 25px !important;
		padding: 4px !important;
	}
	.k-pager-md .k-pager-info, .k-pager-sm .k-pager-info, .k-pager-sm .k-pager-sizes, .k-pager-sm .k-pager-numbers {
		display: initial;
	}
	.k-detail-cell {
		height: 216px !important;
		background-color: #f5f5f5;
	}
}

.mile-grid .k-table-tbody .k-table-row.k-selected, .k-table-list .k-table-row.k-selected {
	background-color: transparent;
	> td {
		background-color: transparent;
	}
}

.mile-grid .k-table-tbody {
	.k-alt {
		background-color: rgba(0, 0, 0, .04) !important;
	}
}

#milestones-grid {
	max-height: 172px;
	> div {
		> .k-grid-header {
			overflow-y: auto;
		}
		> kendo-grid-list > .k-grid-content {
			overflow-y: auto;
		}
	}
	.k-checkbox-wrap {
		display: block;
		text-align: center;
		padding: 0;
	}
}

.new-filter-icon {
	> span > img {
		width: 16px !important;
		height: 16px !important;
		margin-top: 1px;
	}
}
.new-img-16 {
	> span > img {
		width: 16px !important;
		height: 16px !important;
	}
}
.new-img-18 {
	> span > img {
		width: 18px !important;
		height: 18px !important;
	}
}
.new-img-20 {
	> span > img {
		width: 20px !important;
		height: 20px !important;
	}
}
.new-img-22 {
	> span > img {
		width: 22px !important;
		height: 22px !important;
	}
}
.new-img-24 {
	> span > img {
		width: 24px !important;
		height: 24px !important;
	}
}
.new-img-26 {
	> span > img {
		width: 26px !important;
		height: 26px !important;
	}
}

.icon-24 {
	> kendo-icon-wrapper > kendo-svgicon {
		height: 24px !important;
		width: 24px !important;
		> svg {
			height: 24px !important;
			width: 24px !important;
		}
	}
}

.icon-22 {
	> kendo-icon-wrapper > kendo-svgicon {
		height: 22px !important;
		width: 22px !important;
		> svg {
			height: 22px !important;
			width: 22px !important;
		}
	}
}

.icon-20 {
	> kendo-icon-wrapper > kendo-svgicon {
		height: 20px !important;
		width: 20px !important;
		> svg {
			height: 20px !important;
			width: 20px !important;
		}
	}
}

.icon-18 {
	> kendo-icon-wrapper > kendo-svgicon {
		height: 18px !important;
		width: 18px !important;
		> svg {
			height: 18px !important;
			width: 18px !important;
		}
	}
}

.overview-notes-icon {
	img {
		margin-right: -3px;
	}
}

.header-btn {
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 15px;
	transition: 0.25s;
	background-color: white;
	color: black;
	min-width: 32px !important;
}

.header-btn[disabled] {
	cursor: not-allowed;
	background-color: #a9a9a9 !important;
	color: white !important;
}

.currentlyDragging {
	.k-gantt-timeline {
		cursor: grabbing !important;
	}
}

.gantt-year-view-no-months {
	.k-gantt-timeline {
		> div.k-grid-header {
			padding-right: 0 !important;
			> div > table > tbody {
				> tr:first-child {
					background-color: #fafafa !important;
					height: 40px !important;
				}
				> tr:last-child {
					display: none !important;
				}
			}
		}
	}
	kendo-treelist {
		.k-table-thead > tr {
			height: 40px !important;
			> th {
				padding: 0 8px !important;
			}
		}
	}
}
.k-sort-order {
	display: none !important;
}
#currentUpdateGantt {
	> div > kendo-splitter > kendo-splitter-pane > kendo-treelist > div {
		> div > div > table > thead > tr {
			height: 40px;
			> th > .k-cell-inner > .k-link > .k-column-title {
				white-space: normal;
			}
		}
		> kendo-treelist-list > div {
			scrollbar-width: none;
			margin-right: 0 !important;
		}
	}
	height: calc(100% - 36px);
	border-radius: 0 0 4px 4px;
	.main-progress{
		position: relative;
		height:16px;
	}
	.sub-progress{
		position: absolute;
		width: 100%;
	}
	.planned-progress,
	.actual-progress {
		border-radius: 4px;
	}
	.contract-bar {
		height: 16px;
		background-color: rgb(223, 83, 83);
		border-radius: 4px;
	}
	.current-bar {
		height: 16px;
		background-color: rgb(79, 201, 49);
		border-radius: 4px;
	}
	.progress-bar {
		height: 16px;
		background-color: rgb(0 89 255);
		border-radius: 4px;
	}
	.k-gantt-timeline {
		cursor: grab;
		> div.k-grid-header {
			padding-right: 0;
			> div > table > tbody {
				> tr:first-child {
					background-color: #fafafa;
					height: 28px;
					> td {
						padding: 0;
					}
				}
				> tr:last-child {
					height: 28px;
					font-size: 12px;
					> td {
						padding: 0;
					}
				}
			}
		}
		kendo-gantt-task .k-task, kendo-gantt-summary-task .k-task {
			min-width: 1px;
		}
	}
	kendo-treelist {
		.k-table-thead > tr {
			height: 56px;
			> th {
				padding: 0 8px;
			}
		}
		.k-grid-header {
			overflow-x: hidden;
			overflow-y: hidden;
		}
		tbody > tr.ganttChild {
			> td > kendo-icon-wrapper[name="none"] {
				display: none;
			}
		}
		tbody > tr:not(.ganttChild) {
			> td:nth-child(2) {
				border-left: none;
				padding-left: 5px;
			}
		}
		tbody > tr > td:first-of-type {
			display: flex;
			flex-direction: row;
			justify-content: center;
			vertical-align: middle;
			height: 36px;
			padding: 0;
			> .k-icon-wrapper-host > .k-svg-icon {
				margin: auto 0;
			}
		}
		.k-grid-content td {
			padding: 4px;
		}
		tr.k-selected > td {
			background-color: #1274ac40 !important;
			color: black;
		}
	}
	.k-task-single .k-task-complete {
		background-color: rgb(0 89 255);
	}
}

.red-current-update-gantt {
	background-color: rgb(223, 83, 83);
	cursor: pointer;
}

.green-current-update-gantt {
	background-color: rgb(79, 201, 49);
	cursor: pointer;
}

.blue-current-update-gantt {
	background-color: rgb(0 89 255);
	cursor: pointer;
}

.grey-current-update-gantt {
	background-color: rgb(161 159 159);
	cursor: pointer;
	margin-top: 5px;
}

.hidden-summary-bar {
	opacity: 0;
}

.gantt-legend-items-container {
	display: flex;
	flex-direction: row;
	justify-content: right;
	gap: 10px;
	height: 36px;
	padding-right: 12px;
	font-size: 14px;
	.gantt-legend-item{
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 5px;
		position: relative;
		.fake-gantt-bar {
			height: 16px;
			border-radius: 4px;
			width: 50px;
			margin: auto 0;
		}
		.fake-gantt-bar-label {
			margin: auto 0;
		}
	}
}

.gantt-filter {
	display: flex;
	flex-direction: row;
	justify-content: center;
	> .k-filter > .k-filter-container {
		margin: 14px 0;
	}
}

.downArrowIcon {
	font-size: 25px;
	top: -5px;
	left: 7px;
}

.ganttTopLevel, .ganttTopLevel:hover, .ganttLevel2, .ganttLevel2:hover, .ganttLevel3, .ganttLevel3:hover, .ganttLevel4, .ganttLevel4:hover, .ganttLevel5, .ganttLevel5:hover, .ganttLevel6, .ganttLevel6:hover, .ganttLevel7, .ganttLevel7:hover, .ganttLevel8, .ganttLevel8:hover, .ganttLevel9, .ganttLevel9:hover, .ganttLevel10, .ganttLevel10:hover {
	font-weight: bold;
	color: white;
	> td {
		color: white !important;
	}
}

.k-treeview-leaf.k-selected {
	background-color: #1274ac40 !important;
	color: black;
}

.sched-edit-label {
	display: flex;
	> label {
		align-items: center;
		display: flex;
		margin-bottom: 1px;
	}
	> kendo-switch > .k-switch-track > span {
		margin-top: 1px;
	}
}

html {
	width: 100vw;
	scrollbar-width: thin;
}

.data-date-gantt-line, .data-date-gantt-line-2 {
	width: 1px;
	height: 100%;
	background-color: #ff0000;
	right: 100px;
	position: absolute;
	top: 0;
}

.grouping-option-label {
	cursor: pointer;
	> label {
		cursor: pointer !important;
	}
}

.k-checkbox:checked:focus, .k-checkbox.k-checked.k-focus {
	box-shadow: none;
}

.presets-popup-class {
	width: 232px;
	padding-bottom: 5px;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}
.presets-btns-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 5px;
	padding: 5px;
}
.presets-popup-content {
	padding: 0 5px;
}
.preset-tile {
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, .3);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
	cursor: pointer;
}
.presets-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 5px;
	max-height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 2px;
}
.preset-action-btns {
	display: flex;
	flex-direction: row;
	justify-content: center;
	> button {
		height: 24px;
		margin: auto 0;
	}
}
.preset-tile-desc {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 32px;
	width: 107px;
	padding: 0 0 0 5px;
	> .preset-name {
		height: 16px;
		line-height: 1;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin: auto 0;
	}
}

.preset-tabstrip {
	margin: 0 3px 0 2px;
	height: 100%;
	> .k-tabstrip-content {
		border-width: 2px;
		border-style: groove;
		border-color: rgb(192, 192, 192);
		border-radius: 0 4px 4px 4px;
		border-top-width: 2px !important;
		outline-style: none;
	}
}

#unsavedChangesReportWindow {
	> .k-window-content {
		overflow-x: hidden;
	}
}

@media print {
	.scheduleDelayGrid tr {
		display: none;
	}

	.scheduleDelayGrid tr.k-alt:nth-child(-n + 9),
	.scheduleDelayGrid tr:nth-child(-n + 9) {
		display: table-row; // Show only the first 9 rows or the rows you need
	}
}

#addGroupDialog {
	> .k-dialog > .k-dialog-content {
		border-bottom: none;
		border-radius: 0;
	}
}

#addToFolderDialog {
	> .k-dialog > .k-dialog-content {
		border-bottom: none;
		border-radius: 0;
	}
}

.manage-report-treeview {
	> div > kendo-treeview {
		max-height: 347px;
	}
}

.report-icons {
	width: 30px;
	height: 24px;
	> kendo-icon-wrapper > kendo-svgicon {
		margin-right: 0 !important;
	}
}

.report-groups-treeview {
	max-height: 347px;
	.k-treeview-leaf:focus, .k-treeview-leaf.k-focus {
		box-shadow: none;
	}
	> ul > li {
		padding: 0;
		> div {
			width: 100%;
			> span {
				width: 100%;
				height: 28px;
				padding-inline: 8px 2px !important;
				cursor: pointer;
				> span {
					width: 100%;
					> span {
						width: 100%;
					}
				}
			}
		}
	}
}

.report-selection-treeview {
	//max-height: 347px;
	.k-treeview-leaf:focus, .k-treeview-leaf.k-focus {
		box-shadow: none;
	}
	> ul > li {
		padding: 0;
		> div {
			width: calc(100% - 3px);
			> span {
				width: 100%;
				height: 28px;
				padding-inline: 0 2px !important;
				cursor: pointer;
				> span {
					width: 100%;
					> span {
						width: calc(100% + 2px);
					}
				}
			}
		}
	}
}

kendo-checkbox, kendo-textbox, .k-checkbox {
	border-color: rgba(0, 0, 0, 0.24) !important;
	> input {
		border-color: rgba(0, 0, 0, 0.24) !important;
	}
	> input:checked {
		border-color: #039BE5 !important;
		background-color: #039BE5 !important;
	}
}

kendo-dropdownlist, kendo-filter-group > div > div > button, kendo-filter-group > div > div > div > button, input.k-radio, kendo-autocomplete, kendo-combobox, kendo-dropdowntree, kendo-multiselect, kendo-numerictextbox, kendo-textarea {
	border-color: rgba(0, 0, 0, 0.24) !important;
}

kendo-filter-group > div > div > div > button.k-selected, input.k-radio:checked {
	border-color: var(--kendo-color-primary, #ff6358) !important;
	background-color: var(--kendo-color-primary, #ff6358) !important;
}

.k-input-solid.k-invalid, .k-input-solid.ng-invalid.ng-touched, .k-input-solid.ng-invalid.ng-dirty {
	border-color: var(--kendo-color-error, #f31700) !important;
}

.quick-save-report-fields {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 10px;
	.k-form-field-error > kendo-label {
		padding-bottom: 15px;
	}
}

.layout-name-container {
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.k-form-field-error > kendo-label {
		padding-bottom: 15px;
	}
}

.group-item-template {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	.group-name {
		margin: auto 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 190px;
		vertical-align: middle;
	}
	.actions {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
}

.white-btn-img {
	> span > img {
		filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(109deg) brightness(102%) contrast(105%);
	}
}

.k-button-md.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-md.k-icon-button .k-button-icon.k-icon-md > svg {
	height: 18px;
	width: 18px;
}

.core-risk-mitigation-fieldset-wrapper {
	> fieldset {
		grid-template-columns: none !important;
		> .core-risk-fieldset-wrap {
			display: grid;
			grid-template-columns: 50% calc(25% - 10px) calc(25% - 10px);
			gap: 10px;
			> kendo-formfield {
				height: 52px;
				> kendo-label {
					//height: 52px;
					width: 122px !important;
					white-space: nowrap;
				}
				.core-risk-min-var-label {
					width: 182px !important;
				}
				.core-risk-max-var-label {
					width: 204px !important;
				}
				> .k-form-field-wrap {
					max-width: 100%;
					height: 52px;
				}
			}
		}
	}
}

.core-risk-section-title {
	margin-bottom: -8px;
	margin-top: 8px;
	font-weight: bold;
}

.full-opacity-formfield > kendo-label > label {
	opacity: 1 !important;
}

.k-picker-solid.k-invalid, .k-picker-solid.ng-invalid.ng-touched, .k-picker-solid.ng-invalid.ng-dirty {
	border-color: var(--kendo-color-error, #f31700) !important;
}

kendo-formerror {
	white-space: nowrap;
}


.actvCodesColorPicker {
	height: 26px;
	width: 26px;
	margin-right: 3px;
	border-color: #c2c2c2;
	> .k-input-inner {
		height: 20px !important;
		width: 20px !important;
		padding: 2px !important;
	}
	> button {
		display: none;
	}
}

#presetDeleteDialog {
	z-index: 10005;
}

.allBtn > kendo-icon-wrapper > kendo-svgicon > svg {
	height: 18px;
	width: 18px;
}

.structureBtn > span > img {
	height: 16px !important;
	width: 16px !important;
}

#monte-carlo-risk-selection-grid > div {
	.k-grid-header {
		scrollbar-width: thin;
	}
	> kendo-grid-list > .k-grid-content {
		scrollbar-width: thin;
	}
	.k-grid-footer {
		overflow-x: hidden;
		overflow-y: scroll;
		scrollbar-width: thin;
	}
}

.k-pdf-export tr.k-table-row.k-alt.k-table-alt-row.ganttChild, .k-pdf-export tr.k-alt.k-table-alt-row.k-table-row {
	background-color: whitesmoke !important;
}

//these 4 classes are for dragula. some customization was added for the overview tab selected components drag/drop
/* in-flight clone */
.gu-mirror {
	position: fixed !important;
	margin: 0 !important;
	z-index: 999999 !important;
	opacity: 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	filter: alpha(opacity=80);
	pointer-events: none;
	border: 1px solid rgba(0, 0, 0, 0.24);
	border-radius: 4px;
	background-color: white;
	> .option {
		width: 100% !important;
		> .moveContainer {
			padding: 0 2px;
			margin-top: 2px;
			> .dragHandle {
				margin-top: 1px;
				width: 24px;
				height: 24px;
			}
		}
		> .option-name {
			width: calc(100% - 53px) !important;
			vertical-align: middle;
			//padding-left: 2px;
		}
		> .btn-row > .itemBtn {
			height: 24px;
			width: 24px;
			margin-top: 1px;
		}
	}
}
/* high-performance display:none; helper */
.gu-hide {
	left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;

}
/* added to the source element while its mirror is dragged */
.gu-transit {
	opacity: 0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
	filter: alpha(opacity=20);
}

.toolbar-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.data-date-blurb {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: auto 0;
	padding-left: 10px;
	font-size: 14px;
}

.drivingPathDropdownTree {
	max-width: 400px;
	border-radius: 4px;
	kendo-treeview {
		.k-disabled	> span > span > span {
			margin-left: 0 !important;
		}
		.k-treeview-leaf {
			//max-width: 350px;
			//white-space: nowrap;
			//overflow: hidden;
			//text-overflow: ellipsis;
			//display: inline-block;
			background-color: white !important;
			.k-treeview-leaf-text {
				height: 20px;
			}
		}
		.k-treeview-item[aria-level='2'] {
			padding: 0;
			cursor: pointer;
		}
	}
	.k-list-filter {
		.k-input-prefix {
			padding-left: 2px;
			margin-top: 1px;
		}
	}
}

.milestone-item {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	position: relative;
	height: 20px;
}

.milestone-item-finish-icon {
	position: absolute;
	top: -3px;
}

#drivingPathGantt {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	.k-grid-header, .k-grid-content {
		scrollbar-width: thin;
	}
	.k-task-single .k-task-complete {
		background-color: rgb(0, 89, 255);
	}
	.k-gantt-timeline {
		kendo-gantt-task {
			margin-left: -24.5px;
		}
	}
	kendo-treelist-list > .k-grid-content {
		margin-right: 0 !important;
	}
	kendo-gantt-summary-task {
		margin-top: 5px;
		> div {
			min-width: 2px;
		}
	}
}

.overrideSelection {
	background-color: rgba(18, 116, 172, 0.25) !important;
	border-radius: 4px;
}

.overview-br-top {
	border-radius: 4px 4px 0 0;
}
.overview-br-bottom {
	border-radius: 0 0 4px 4px;
}

.tab-page-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.expansionpanel-container-focus-tab {
	.k-expander.k-focus {
		box-shadow: none;
	}
	kendo-expansionpanel {
		border-radius: 4px;
		background-color: white;
		border: none;
		margin-bottom: 1px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
		.k-expander-header[aria-expanded=true] {
			background-color: #edecec !important;
			//.panel-score {
			//	background-color: #edecec !important;
			//}
		}
		.k-expander-header {
			padding: 4.6px 5px;
			background-color: #edecec;
			position: relative;
			.k-expander-indicator {
				position: absolute;
				left: 0;
				margin-left: 8px;
				> kendo-icon-wrapper > kendo-svgicon {
					height: 20px !important;
					width: 20px !important;
				}
			}
			.expansionpanel-title-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				color: black;
				font-weight: bold;
				.panel-name {
					margin: auto 0;
					padding-left: 30px;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					gap: 2px;
				}
				.scores-container {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					gap: 5px;
					width: min-content;
					min-width: 550px;
					white-space: nowrap;
					.panel-score {
						background-color: white;
						border-radius: 4px;
						padding: 4px 12px;
						display: flex;
						flex-direction: row;
						gap: 2px;
						height: 54.11px;
						width: 180px;
						.score-label {
							width: calc(100% - 64px);
						}
						span {
							margin: auto 0;
							font-size: 20px;
							color: black;
						}
						.score-val {
							display: inline-block;
							width: 64px;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							text-align: center;
						}
					}
				}
			}
		}
		.k-expander-content-wrapper > .k-expander-content {
			padding: 11px;
		}
		.expansionpanel-content-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
	}
}

.focusActvFilter {
	.k-chip {
		width: auto;
		min-width: 144px;
	}
}

.invis-tooltip {
	position: absolute;
	width: 66.66px;
	height: 32px;
	top: 2px;
	border-radius: 20px;
}

.disabled-invis {
	z-index: 5;
	cursor: not-allowed;
}

.focusTabHeaderSizing {
	height: 23px !important;
	font-size: 13px !important;
	> * {
		font-size: 13px !important;
	}
}

.focusTabFontSizing {
	font-size: 13px !important;
}

.vert-line {
	border-left: 1px solid #C8CAD6;
	width: 1px;
	height: 40px;
	margin: auto 0;
}

.compareGrid {
	kendo-grid-list {
		tbody > tr > td {
			cursor: pointer;
		}
	}
}

.compare-selected-row {
	background-color: rgba(18, 116, 172, 0.25) !important
}
.showTheName {
	> .k-input-values {
		> kendo-taglist > div {
			width: auto;
		}
		> kendo-searchbar {
			width: 0;
			opacity: 0;
		}
		> kendo-searchbar:hover {
			cursor: pointer;
			> input {
				cursor: pointer;
			}
		}
	}
}

.act-compl-header {
	color: red;
	> span > .k-link {
		overflow-x: visible !important;
		padding: 0;
		padding-inline: 0 !important;
	}
}

/*.focus-expansion > .k-expander-content-wrapper {
	max-height: 700px;
	overflow-y: auto;
}*/

.focus-tab-panel-header{
	position: fixed !important;
	top: 109px;
	z-index: 8;
	width: calc(100dvw - 20px);
}
.focus-tab-panel-header-but-off{
	position: absolute !important;
	top: unset;
	bottom: 0;
	z-index: 8;
	width: calc(100dvw - 20px);
}
.focus-expansion {
	position: relative;
}
.k-chart-tooltip th {
	white-space: pre-line;
}
